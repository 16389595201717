import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート
import { useLocation } from 'react-router-dom';

const RankingDetailPage = () => {
      // URLからクエリパラメータを取得
  const query = new URLSearchParams(useLocation().search);
  const s3path = query.get('s3path');
  const tytle = query.get('tytle');

  const [links, setLinks] = useState([]);

  const imagePath = `https://s3.ap-northeast-1.amazonaws.com/neko-life.com/${s3path}`;

  useEffect(() => {
    fetch(`http://localhost:5000/api/files?prefix=${s3path}`)
      .then((response) => response.json())
      .then((data) => {
        const fetchedLinks = data.map((file) => ({
          to: `/image?lastPath=${file.key}&tytle=${file.key.split('/').pop()}`,
          label: file.key.split('/').pop(),
        }));
        setLinks(fetchedLinks);
      });
  }, [s3path]);
  
    // // 動的に生成するリンクデータ
    // const links = [
    //     { to: `/image?lastPath=${s3path}/1.png&tytle=収益１`, label: "収益１" },
    //     { to: "/video?lastPath=rafael/3.mov&tytle=4列目②(無加工)", label: "4列目②(無加工)" },
    //     { to: "/video?lastPath=rafael/4.mov&tytle=4列目③(無加工)", label: "4列目③(無加工)" },
    //     { to: "/video?lastPath=rafael/5.mov&tytle=5列目①(無加工)", label: "5列目①(無加工)" },
    //     { to: "/video?lastPath=rafael/6.mov&tytle=5列目②(無加工)", label: "5列目②(無加工)" },
    //     { to: "/video?lastPath=rafael/7.mov&tytle=5列目③(無加工)", label: "5列目③(無加工)" },
    // ];

    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    {tytle}
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <ul>
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.to} className="link-style">
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default RankingDetailPage;