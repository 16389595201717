import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート
import { useLocation } from 'react-router-dom';

const DansionPage = () => {

    // クエリパラメータを取得
    const query = new URLSearchParams(useLocation().search);
    const event = query.get('event') || '未選択';

    // リンクデータを配列で定義
    const links1 = [
        { to: `/image?lastPath=${event}/1.png&tytle=ダンジョン 1階層`, label: "1階層" },
        { to: `/image?lastPath=${event}/2.png&tytle=ダンジョン 2階層`, label: "2階層" },
        { to: `/image?lastPath=${event}/3.png&tytle=ダンジョン 3階層`, label: "3階層" },
        { to: `/image?lastPath=${event}/4.png&tytle=ダンジョン 4階層`, label: "4階層" },
        { to: `/image?lastPath=${event}/5.png&tytle=ダンジョン 5階層`, label: "5階層" },
        { to: `/image?lastPath=${event}/6.png&tytle=ダンジョン 6階層`, label: "6階層" },
        { to: `/image?lastPath=${event}/7.png&tytle=ダンジョン 7階層`, label: "7階層" },
        { to: `/image?lastPath=${event}/8.png&tytle=ダンジョン 8階層`, label: "8階層" },
        { to: `/image?lastPath=${event}/9.png&tytle=ダンジョン 9階層`, label: "9階層" },
        { to: `/image?lastPath=${event}/10.png&tytle=ダンジョン 10階層`, label: "10階層" },
        { to: `/image?lastPath=${event}/11.jpg&tytle=ダンジョン 11階層`, label: "11階層" },
        { to: `/image?lastPath=${event}/12.jpg&tytle=ダンジョン 12階層`, label: "12階層" },
        { to: `/image?lastPath=${event}/13.jpg&tytle=ダンジョン 13階層`, label: "13階層" },
        { to: `/image?lastPath=${event}/14.jpg&tytle=ダンジョン 14階層`, label: "14階層" },
        { to: `/image?lastPath=${event}/15.jpg&tytle=ダンジョン 15階層`, label: "15階層" },
        { to: `/image?lastPath=${event}/16.jpg&tytle=ダンジョン 16階層`, label: "16階層" },
        { to: `/image?lastPath=${event}/17.jpg&tytle=ダンジョン 17階層`, label: "17階層" },
        { to: `/image?lastPath=${event}/18.jpg&tytle=ダンジョン 18階層`, label: "18階層" },
        { to: `/image?lastPath=${event}/19.jpg&tytle=ダンジョン 19階層`, label: "19階層" },
        { to: `/image?lastPath=${event}/20.jpg&tytle=ダンジョン 20階層`, label: "20階層" },
        { to: `/image?lastPath=${event}/21.jpg&tytle=ダンジョン 21階層`, label: "21階層" },
        { to: `/image?lastPath=${event}/22.jpg&tytle=ダンジョン 22階層`, label: "22階層" },
        { to: `/image?lastPath=${event}/23.jpg&tytle=ダンジョン 23階層`, label: "23階層" },
        { to: `/image?lastPath=${event}/24.jpg&tytle=ダンジョン 24階層`, label: "24階層" },
        { to: `/image?lastPath=${event}/25.jpg&tytle=ダンジョン 25階層`, label: "25階層" },
        { to: `/image?lastPath=${event}/26.jpg&tytle=ダンジョン 26階層`, label: "26階層" },
        { to: `/video?lastPath=${event}/27.mov&tytle=ダンジョン 27階層`, label: "27階層" },
        { to: `/video?lastPath=${event}/28.mov&tytle=ダンジョン 28階層`, label: "28階層" },
        { to: `/video?lastPath=${event}/29.mov&tytle=ダンジョン 29階層`, label: "29階層" },
        { to: `/video?lastPath=${event}/30.mov&tytle=ダンジョン 30階層`, label: "30階層" },
        { to: `/video?lastPath=${event}/31.mov&tytle=ダンジョン 31階層`, label: "31階層" },
        { to: `/video?lastPath=${event}/32.mov&tytle=ダンジョン 32階層`, label: "32階層" },
        { to: `/video?lastPath=${event}/33.mov&tytle=ダンジョン 33階層`, label: "33階層" },
        { to: `/video?lastPath=${event}/34.mov&tytle=ダンジョン 34階層`, label: "34階層" },
        { to: `/video?lastPath=${event}/35.mov&tytle=ダンジョン 35階層`, label: "35階層" },
        { to: `/video?lastPath=${event}/36.mov&tytle=ダンジョン 36階層`, label: "36階層" },
        { to: `/video?lastPath=${event}/37.mov&tytle=ダンジョン 37階層`, label: "37階層" },
        { to: `/video?lastPath=${event}/38.mov&tytle=ダンジョン 38階層`, label: "38階層" },
        { to: `/video?lastPath=${event}/39.mov&tytle=ダンジョン 39階層`, label: "39階層" },
        { to: `/video?lastPath=${event}/40.mov&tytle=ダンジョン 40階層`, label: "40階層" },
        { to: `/video?lastPath=${event}/41.mov&tytle=ダンジョン 41階層`, label: "41階層" },
        { to: `/video?lastPath=${event}/42.mov&tytle=ダンジョン 42階層`, label: "42階層" },
        { to: `/video?lastPath=${event}/43.mov&tytle=ダンジョン 43階層`, label: "43階層" },
        { to: `/video?lastPath=${event}/44.mov&tytle=ダンジョン 44階層`, label: "44階層" },
        { to: `/video?lastPath=${event}/45.mov&tytle=ダンジョン 45階層`, label: "45階層" },
        { to: `/video?lastPath=${event}/46.mp4&tytle=ダンジョン 46階層`, label: "46階層" },
        { to: `/video?lastPath=${event}/47.mp4&tytle=ダンジョン 47階層`, label: "47階層" },
        { to: `/video?lastPath=${event}/48.mp4&tytle=ダンジョン 48階層`, label: "48階層" },
        { to: `/video?lastPath=${event}/49.mp4&tytle=ダンジョン 49階層`, label: "49階層" },
        { to: `/video?lastPath=${event}/50.mp4&tytle=ダンジョン 50階層`, label: "50階層" },
        { to: `/video?lastPath=${event}/51.mp4&tytle=ダンジョン 51階層`, label: "51階層" },
        { to: `/video?lastPath=${event}/52.mp4&tytle=ダンジョン 52階層`, label: "52階層" },
        { to: `/video?lastPath=${event}/53.mp4&tytle=ダンジョン 53階層`, label: "53階層" },
        { to: `/video?lastPath=${event}/54.mp4&tytle=ダンジョン 54階層`, label: "54階層" },
        { to: `/video?lastPath=${event}/55.mp4&tytle=ダンジョン 55階層`, label: "55階層" },
        { to: `/video?lastPath=${event}/56.mov&tytle=ダンジョン 56階層`, label: "56階層" },
        { to: `/video?lastPath=${event}/57.mov&tytle=ダンジョン 57階層`, label: "57階層" },
        { to: `/video?lastPath=${event}/58.mov&tytle=ダンジョン 58階層`, label: "58階層" },
        { to: `/video?lastPath=${event}/59.mov&tytle=ダンジョン 59階層`, label: "59階層" },
        { to: `/video?lastPath=${event}/60.mov&tytle=ダンジョン 60階層`, label: "60階層" },
        { to: `/video?lastPath=${event}/61.mp4&tytle=ダンジョン 61階層`, label: "61階層" },
        { to: `/video?lastPath=${event}/62.mp4&tytle=ダンジョン 62階層`, label: "62階層" },
        { to: `/video?lastPath=${event}/63.mp4&tytle=ダンジョン 63階層`, label: "63階層" },
        { to: `/video?lastPath=${event}/64.mp4&tytle=ダンジョン 64階層`, label: "64階層" },
        { to: `/video?lastPath=${event}/65.mp4&tytle=ダンジョン 65階層`, label: "65階層" },
    ];

    const links2 = [
        { to: `/video?lastPath=${event}/1.mov&tytle=ダンジョン 1階層`, label: "第２回　1階層" },
        { to: `/video?lastPath=${event}/2.mov&tytle=ダンジョン 2階層`, label: "第２回　2階層" },
        { to: `/video?lastPath=${event}/3.mov&tytle=ダンジョン 3階層`, label: "第２回　3階層" },
        { to: `/video?lastPath=${event}/4.mov&tytle=ダンジョン 4階層`, label: "第２回　4階層" },
        { to: `/video?lastPath=${event}/5.mov&tytle=ダンジョン 5階層`, label: "第２回　5階層" },
        { to: `/video?lastPath=${event}/6.mov&tytle=ダンジョン 6階層`, label: "第２回　6階層" },
        { to: `/video?lastPath=${event}/7.mov&tytle=ダンジョン 7階層`, label: "第２回　7階層" },
        { to: `/video?lastPath=${event}/8.mov&tytle=ダンジョン 8階層`, label: "第２回　8階層" },
        { to: `/video?lastPath=${event}/9.mov&tytle=ダンジョン 9階層`, label: "第２回　9階層" },
        { to: `/video?lastPath=${event}/10.mov&tytle=ダンジョン 10階層`, label: "第２回　10階層" },
        { to: `/video?lastPath=${event}/11.mov&tytle=ダンジョン 11階層`, label: "第２回　11階層" },
        { to: `/video?lastPath=${event}/12.mov&tytle=ダンジョン 12階層`, label: "第２回　12階層" },
        { to: `/video?lastPath=${event}/13.mov&tytle=ダンジョン 13階層`, label: "第２回　13階層" },
        { to: `/video?lastPath=${event}/14.mov&tytle=ダンジョン 14階層`, label: "第２回　14階層" },
        { to: `/video?lastPath=${event}/15.mov&tytle=ダンジョン 15階層`, label: "第２回　15階層" },
        { to: `/video?lastPath=${event}/16.mov&tytle=ダンジョン 16階層`, label: "第２回　16階層" },
        { to: `/video?lastPath=${event}/17.mov&tytle=ダンジョン 17階層`, label: "第２回　17階層" },
        { to: `/video?lastPath=${event}/18.mov&tytle=ダンジョン 18階層`, label: "第２回　18階層" },
        { to: `/video?lastPath=${event}/19.mov&tytle=ダンジョン 19階層`, label: "第２回　19階層" },
        // { to: `/video?lastPath=${event}/20.mov&tytle=ダンジョン 20階層`, label: "第２回　20階層" },
        // { to: `/video?lastPath=${event}/21.mov&tytle=ダンジョン 21階層`, label: "第２回　21階層" },
        // { to: `/video?lastPath=${event}/22.mov&tytle=ダンジョン 22階層`, label: "第２回　22階層" },
        // { to: `/video?lastPath=${event}/23.mov&tytle=ダンジョン 23階層`, label: "第２回　23階層" },
        // { to: `/video?lastPath=${event}/24.mov&tytle=ダンジョン 24階層`, label: "第２回　24階層" },
        // { to: `/video?lastPath=${event}/25.mov&tytle=ダンジョン 25階層`, label: "第２回　25階層" },
        // { to: `/video?lastPath=${event}/26.mov&tytle=ダンジョン 26階層`, label: "第２回　26階層" },
        // { to: `/video?lastPath=${event}/27.mov&tytle=ダンジョン 27階層`, label: "第２回　27階層" },
        // { to: `/video?lastPath=${event}/28.mov&tytle=ダンジョン 28階層`, label: "第２回　28階層" },
        // { to: `/video?lastPath=${event}/29.mov&tytle=ダンジョン 29階層`, label: "第２回　29階層" },
        // { to: `/video?lastPath=${event}/30.mov&tytle=ダンジョン 30階層`, label: "第２回　30階層" },
        // { to: `/video?lastPath=${event}/31.mov&tytle=ダンジョン 31階層`, label: "第２回　31階層" },
        // { to: `/video?lastPath=${event}/32.mov&tytle=ダンジョン 32階層`, label: "第２回　32階層" },
        // { to: `/video?lastPath=${event}/33.mov&tytle=ダンジョン 33階層`, label: "第２回　33階層" },
        // { to: `/video?lastPath=${event}/34.mov&tytle=ダンジョン 34階層`, label: "第２回　34階層" },
        // { to: `/video?lastPath=${event}/35.mov&tytle=ダンジョン 35階層`, label: "第２回　35階層" },
        // { to: `/video?lastPath=${event}/36.mov&tytle=ダンジョン 36階層`, label: "第２回　36階層" },
        // { to: `/video?lastPath=${event}/37.mov&tytle=ダンジョン 37階層`, label: "第２回　37階層" },
        // { to: `/video?lastPath=${event}/38.mov&tytle=ダンジョン 38階層`, label: "第２回　38階層" },
        // { to: `/video?lastPath=${event}/39.mov&tytle=ダンジョン 39階層`, label: "第２回　39階層" },
        // { to: `/video?lastPath=${event}/40.mov&tytle=ダンジョン 40階層`, label: "第２回　40階層" },
        // { to: `/video?lastPath=${event}/41.mov&tytle=ダンジョン 41階層`, label: "第２回　41階層" },
        // { to: `/video?lastPath=${event}/42.mov&tytle=ダンジョン 42階層`, label: "第２回　42階層" },
        // { to: `/video?lastPath=${event}/43.mov&tytle=ダンジョン 43階層`, label: "第２回　43階層" },
        // { to: `/video?lastPath=${event}/44.mov&tytle=ダンジョン 44階層`, label: "第２回　44階層" },
        // { to: `/video?lastPath=${event}/45.mov&tytle=ダンジョン 45階層`, label: "第２回　45階層" },
        // { to: `/video?lastPath=${event}/46.mov&tytle=ダンジョン 46階層`, label: "第２回　46階層" },
        // { to: `/video?lastPath=${event}/47.mov&tytle=ダンジョン 47階層`, label: "第２回　47階層" },
        // { to: `/video?lastPath=${event}/48.mov&tytle=ダンジョン 48階層`, label: "第２回　48階層" },
        // { to: `/video?lastPath=${event}/49.mov&tytle=ダンジョン 49階層`, label: "第２回　49階層" },
        // { to: `/video?lastPath=${event}/50.mov&tytle=ダンジョン 50階層`, label: "第２回　50階層" },
        // { to: `/video?lastPath=${event}/51.mov&tytle=ダンジョン 51階層`, label: "第２回　51階層" },
        // { to: `/video?lastPath=${event}/52.mov&tytle=ダンジョン 52階層`, label: "第２回　52階層" },
        // { to: `/video?lastPath=${event}/53.mov&tytle=ダンジョン 53階層`, label: "第２回　53階層" },
        // { to: `/video?lastPath=${event}/54.mov&tytle=ダンジョン 54階層`, label: "第２回　54階層" },
        // { to: `/video?lastPath=${event}/55.mov&tytle=ダンジョン 55階層`, label: "第２回　55階層" },
        // { to: `/video?lastPath=${event}/56.mov&tytle=ダンジョン 56階層`, label: "第２回　56階層" },
        // { to: `/video?lastPath=${event}/57.mov&tytle=ダンジョン 57階層`, label: "第２回　57階層" },
        // { to: `/video?lastPath=${event}/58.mov&tytle=ダンジョン 58階層`, label: "第２回　58階層" },
        // { to: `/video?lastPath=${event}/59.mov&tytle=ダンジョン 59階層`, label: "第２回　59階層" },
        // { to: `/video?lastPath=${event}/60.mov&tytle=ダンジョン 60階層`, label: "第２回　60階層" },
        // { to: `/video?lastPath=${event}/61.mov&tytle=ダンジョン 61階層`, label: "第２回　61階層" },
        // { to: `/video?lastPath=${event}/62.mov&tytle=ダンジョン 62階層`, label: "第２回　62階層" },
        // { to: `/video?lastPath=${event}/63.mov&tytle=ダンジョン 63階層`, label: "第２回　63階層" },
        // { to: `/video?lastPath=${event}/64.mov&tytle=ダンジョン 64階層`, label: "第２回　64階層" },
        // { to: `/video?lastPath=${event}/65.mov&tytle=ダンジョン 65階層`, label: "第２回　65階層" },
    ];

    const links = event === 'dangeon/2' ? links2 : links1;

    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    ダンジョン鉱山
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <h2 className="text-xl md:text-2xl mb-6 text-[#FFD700]">
                        階層を選択してください
                    </h2>
                    <ul>
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.to} className="link-style">
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default DansionPage;
