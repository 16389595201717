import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート

const RankingPage = () => {
    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    ランキングアーカイブ
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <ul>
                        <li><Link to="/rankingDetail?tytle=収益&s3path=ranking/syueki" className="link-style">収益</Link></li>
                        <li><Link to="/rankingDetail?tytle=パワー&s3path=ranking/pawer" className="link-style">パワー</Link></li>
                        <li><Link to="/rankingDetail?tytle=親密度&s3path=ranking/shinmitsu" className="link-style">親密度</Link></li>
                        <li><Link to="/rankingDetail?tytle=加護&s3path=ranking/kago" className="link-style">加護</Link></li>
                        <li><Link to="/rankingDetail?tytle=晩餐会&s3path=ranking/bansankai" className="link-style">晩餐会</Link></li>
                        <li><Link to="/rankingDetail?tytle=水晶石&s3path=ranking/suisyo" className="link-style">水晶石</Link></li>
                        <li><Link to="/rankingDetail?tytle=商会影響力&s3path=ranking/syokai" className="link-style">商会影響力</Link></li>
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default RankingPage;