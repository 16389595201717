import React from 'react';
import { useLocation } from 'react-router-dom';
import backgroundImage from './icon/main.png'; // 背景画像をインポート
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // CSSファイルのインポート

function ImagePage() {
    // URLからクエリパラメータを取得
    const query = new URLSearchParams(useLocation().search);
    const lastPath = query.get('lastPath');
    const tytle = query.get('tytle');
  
    // 動画のURLを動的に生成
    const imagePath = `https://s3.ap-northeast-1.amazonaws.com/neko-life.com/${lastPath}`;

    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    {tytle}
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>
            {/* 生成したURLをsrcに設定 */}
            <img src={imagePath} alt={`${tytle}`} />
        </div>
    );
}

export default ImagePage;