import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import DansionPage from './DansionPage';
import ImagePage from './ImagePage';
import VideoPage from './VideoPage';
import RafaelPage from './RafaelPage';
import RankingPage from './RankingPage';
import RankingDetailPage from './RankingDetailPage';
import SelectPage from './SelectPage';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/dansion" element={<DansionPage />} />
                <Route path="/rafael" element={<RafaelPage />} />
                <Route path="/image" element={<ImagePage />} />
                <Route path="/video" element={<VideoPage />} />
                <Route path="/ranking" element={<RankingPage />} />
                <Route path="/rankingDetail" element={<RankingDetailPage />} />
                <Route path="/select" element={<SelectPage />} />
            </Routes>
        </Router>
    );
};

export default App;