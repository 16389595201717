import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート

const RafaelPage = () => {
    // 動的に生成するリンクデータ
    const links = [
        { to: "/video?lastPath=rafael/1.mov&tytle=3列目(無加工)", label: "3列目(無加工)" },
        { to: "/video?lastPath=rafael/2.mov&tytle=4列目①(無加工)", label: "4列目①(無加工)" },
        { to: "/video?lastPath=rafael/3.mov&tytle=4列目②(無加工)", label: "4列目②(無加工)" },
        { to: "/video?lastPath=rafael/4.mov&tytle=4列目③(無加工)", label: "4列目③(無加工)" },
        { to: "/video?lastPath=rafael/5.mov&tytle=5列目①(無加工)", label: "5列目①(無加工)" },
        { to: "/video?lastPath=rafael/6.mov&tytle=5列目②(無加工)", label: "5列目②(無加工)" },
        { to: "/video?lastPath=rafael/7.mov&tytle=5列目③(無加工)", label: "5列目③(無加工)" },
    ];

    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    ラファエル
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <ul>
                        {/* 1件目のリンク */}
                        <li>
                            <Link
                                to="https://www.evernote.com/shard/s352/sh/2d00a715-188e-5ede-9796-7b9826043937/1eIr0jf513lofXa7SJGZI0wLlrntLbv0AFZsGwOfiz0xegBwN0De-wLRbg"
                                className="link-style"
                            >
                                まとめ
                            </Link>
                        </li>
                        {/* その他のリンクをmapでレンダリング */}
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.to} className="link-style">
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default RafaelPage;