import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // ハンバーガーメニューをインポート
import './styles.css'; // MainPageのスタイルを共有
import backgroundImage from './icon/main.png'; // 背景画像をインポート

const SelectPage = () => {
    // 選択可能な「第〇回」のデータ
    const selections = [
        { to: '/dansion?event=dangeon', label: '第1回' },
        { to: '/dansion?event=dangeon/2', label: '第2回' },
    ];

    return (
        <div
            className="min-h-screen text-white font-noto-sans bg-cover bg-center bg-fixed"
            style={{
                backgroundImage: `linear-gradient(rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${backgroundImage})`,
            }}
        >
            <header className="bg-[#2a2a2a] bg-opacity-70 p-4 shadow-lg flex justify-between items-center">
                <h1 className="text-2xl md:text-4xl text-[#FFD700] font-bold">
                    ダンジョン　イベント選択
                </h1>
                <HamburgerMenu /> {/* ハンバーガーメニューを右端に配置 */}
            </header>

            <main className="container mx-auto px-4 py-8">
                <div className="bg-[#2a2a2a] bg-opacity-80 rounded-lg p-6 shadow-xl">
                    <ul>
                        {selections.map((selection, index) => (
                            <li key={index}>
                                <Link to={selection.to} className="link-style">
                                    {selection.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default SelectPage;